.onlyWeb {
  display: block !important;
}

.onWeb {
  display: flex !important;
}

.onMobile {
  display: none !important;
}

.navBarContainer img {
  height: 40px;
}

.navBarContainer {
  width: 100%;
  position: fixed;
  z-index: 1005;
}

.navBar {
  margin: 1.5em 6em 0.25em 6em;
  padding: 10px 20px !important;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

.navLinkDisable {
  cursor: not-allowed !important;
}

.navLinkDisable,
.navLink {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: Secular One;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0px 10px;
  cursor: pointer;
  text-decoration: none !important;
  color: #000000 !important;
}

.navLink:hover {
  color: #666 !important;
}

.navLink svg {
  color: black;
}

.cartLink {
  position: relative;
  color: black !important;
  text-decoration: none;
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cartBadge {
  position: absolute;
  background-color: #0d6efd;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}

/* Posicionamento específico para desktop */
@media (min-width: 768px) {
  .cartBadge {
    top: -8px;
    right: -8px;
  }
}

/* Posicionamento específico para mobile */
@media (max-width: 767px) {
  .cartBadge {
    top: -5px;
    left: 40px;
  }

  .cartLink {
    margin: 8px 10px;
  }

  /* Se necessário, ajuste o tamanho do ícone no mobile */
  .cartLink svg {
    width: 24px;
    height: 24px;
  }
}

/* Se você estiver usando classes do Bootstrap para o menu mobile, 
 pode precisar destes ajustes adicionais */
:global(.navbar-nav) .cartLink {
  padding: 0.5rem 1rem;
}

@media only screen and (max-width: 900px) {
  .navLink {
    font-size: 13px !important;
  }

  .navBarContainer img {
    height: 30px !important;
  }
}

@media only screen and (max-width: 885px) {
  .navBar {
    margin: 0em 6em 0.25em 6em;
    border-radius: 0px 0px 25px 25px;
  }

  .navBarContainer img {
    height: 20px !important;
  }

  .navSpace {
    padding: 37.5px 20px !important;
  }
}

@media only screen and (max-width: 790px) {
  .navLink {
    font-size: 11.75px !important;
  }
}

@media only screen and (max-width: 750px) {
  .onlyWeb {
    display: none !important;
  }

  .onMobile {
    display: flex !important;
  }

  .navBar {
    margin: 0em 1em 0.25em 1em;
  }

  .navLink {
    font-size: 15px !important;
  }

  .navBarContainer img {
    height: 30px !important;
  }

  .navSpace {
    padding: 37.5px 20px !important;
  }
}


.navLinkLogout {
  z-index: 1015;
  padding: 0 !important;
  color: #000 !important;
  width: 15%;
  height: 22px;
}

.navSpace {
  padding: 50px 20px;
  height: 70px;
}

.loggedSpace {
  width: 200px;
  height: 50px;
  color: black;
  background: #D9D9D9;
  border-radius: 25px;
  position: relative;
  z-index: 1001;
}

.loggedSpaceClickableArea {
  z-index: 1010;
  width: 200px;
  height: 50px;
  position: absolute;
  border-top: 50px solid transparent;
  border-left: 60px solid transparent;
  border-right: 0px solid transparent;
  cursor: pointer;
}

.loggedImage {
  z-index: 1006;
  position: relative;
  background-size: cover;
  background-position: center center;
  float: right;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.loggedInfo {
  z-index: 1005;
  width: 100%;
  height: 50px;
  position: absolute;
}

.loggedSpace a,
.loggedSpace a:hover {
  color: #000;
  text-decoration: none !important;
}

.loggedName {
  margin-left: 15px;
  margin-top: 2px;
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  height: 20px;
}

.logoutButton_MemberState {
  margin-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.memberStatus {
  border-radius: 25px;
  margin-right: 7px;
  margin-left: 25px;
  width: 125px;
  height: 22px;
  float: right;
}

.memberStatus div {
  position: absolute;
  left: 70px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: white;
  line-height: 22px;
  margin-left: 10px;
  z-index: 1001;
}

.moreInfo {
  flex-direction: column;
  padding: 55px 1em 5px 1em;
  width: 200px;
  height: auto;
  background: linear-gradient(0deg, rgba(36, 139, 227, 0.5) 0%, rgba(36, 139, 227, 0.5) 100%), #FFFFFF;
  border-radius: 25px;
  position: absolute;
  right: 20px;
  row-gap: 2px;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 25%);
}

.moreInfo>* {
  padding: 0 !important;
  color: white !important;
}